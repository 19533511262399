<template>
  <div>
    <b-modal modal-class="modal_basic upgradeModal" id="upgrade-ltd-subscription-modal" hide-footer hide-header>
      <i @click="$bvModal.hide('upgrade-ltd-subscription-modal')" style="color:#45a2ff"
         class="close_modal fal fa-times-circle"></i>
      <div class="subscriptions_plan container">
        <div class="main">
          <div class="pricing_page">
            <div class="heading_x">
              <h2>Upgrade Your Subscription <br> Plan To Get More Features</h2>
            </div>

            <div v-if="self.getSubscription._id" class="pricing__box">

              <div class="inner  clear row pricing-table-container">
                <div v-if="subscriptionList && subscriptionList.length && item.level >= level && self.getProfile.subscription.internal_id !== item.internal_id" class="price_box_3 col-md-4 pb-5" v-for="(item,index) in subscriptionList">
                  <div class="inner white_bg">
                    <div class="inner_section">
                      <p class="package">{{ item.display_name }}</p>

                      <h2 class="price">
                        <sup>$</sup>
                        {{ computeOverridePrice(item.price) }}
                        <br>
                        <!--                      <sup>$</sup>-->
                        <span v-if="!isOldLTDSubscription()" class="discounted_price">
                         ${{ item.price }}
                      </span>
                        <br>
                        <sub class="month">Lifetime</sub>
                      </h2>

                      <div class="brand">
                        <span class="bold">{{ item.limits.brands }}</span> Brand
                      </div>

                      <ul class="list_detail">
                        <li class="plan-list">
                          <div class="d-inline-block icon">
                            <i class="fal fa-check pricing-table-icon"></i>
                          </div>
                          <span>{{ computeLimitsValue(item.limits.clicks) }}</span> clicks per month
                        </li>

                        <li class="plan-list">
                          <div class="d-inline-block icon">
                            <i class="fal fa-check pricing-table-icon"></i>
                          </div>
                          <span>{{ computeLimitsValue(item.limits.campaigns) }}</span> Campaigns
                        </li>

                        <li class="plan-list">
                          <div class="d-inline-block icon">
                            <i class="fal fa-check pricing-table-icon"></i>
                          </div>
                          <span>{{ computeLimitsValue(item.limits.retargeting_pixels) }}</span> Retargeting Pixel
                        </li>

                        <li class="plan-list">
                          <div class="d-inline-block icon">
                            <i class="fal fa-check pricing-table-icon"></i>
                          </div>
                          <span>{{ computeLimitsValue(item.limits.links) }}</span> {{ self.getSiteDetails.agency_name }} Links
                        </li>

                        <li class="plan-list">
                          <div class="d-inline-block icon">
                            <i class="fal fa-check pricing-table-icon"></i>
                          </div>
                          <span>{{ computeLimitsValue(item.limits.bio_links) }}</span> Bio-links
                        </li>

                        <template v-if="item.limits.custom_domains === 0">
                          <li class="line_strike">
                            <div class="d-inline-block icon">
                              <i class="fal fa-times pricing-table-icon"></i>
                            </div>
                            <span>{{ computeLimitsValue(item.limits.custom_domains) }} Custom Domains</span>
                          </li>
                        </template>
                        <template v-else>
                          <li class="plan-list">
                            <div class="d-inline-block icon">
                              <i class="fal fa-check pricing-table-icon"></i>
                            </div>
                            <span>{{ computeLimitsValue(item.limits.custom_domains) }} Custom Domains</span>
                          </li>
                        </template>

                        <template v-if="item.limits.team_members === 0">
                          <li class="line_strike">
                            <div class="d-inline-block icon">
                              <i class="fal fa-times pricing-table-icon"></i>
                            </div>
                            <span>{{ computeLimitsValue(item.limits.team_members) }} Team Members</span>
                          </li>
                        </template>
                        <template v-else>
                          <li class="plan-list">
                            <div class="d-inline-block icon">
                              <i class="fal fa-check pricing-table-icon"></i>
                            </div>
                            <span>{{ computeLimitsValue(item.limits.team_members) }} Team Members</span>
                          </li>
                        </template>

                        <li class="d-flex align-items-center"
                            :class="{'line_strike': !item.limits.branding,'plan-list':item.limits.branding}">
                          <div class="d-inline-block icon"><i
                            :class="{'fa-check': item.limits.branding, 'fa-times': !item.limits.branding}"
                            class="fal  pricing-table-icon"></i></div>
                          <span>Remove {{ self.getSiteDetails.agency_name }} Branding</span>
                        </li>

                        <li class="d-flex align-items-center"
                            :class="{'line_strike': !item.limits.traffic_routing,'plan-list':item.limits.traffic_routing}">
                          <div class="d-inline-block icon"><i
                            :class="{'fa-check': item.limits.traffic_routing, 'fa-times': !item.limits.traffic_routing}"
                            class="fal  pricing-table-icon"></i></div>
                          <span>Traffic Routing</span>
                          <div class="avatar mr-0 ml-3">
                            <i class="far fa-question-circle custom_tooltip tag_tooltip" title="Platform">
                              <div class="tool_tip_box">
                                <div class="inner_box">
                                  <p>Route users to specific destination URLs based on multiple factors like Country,
                                    Browser, Device, Operating System, Day of Week, Date and IP Address.</p>
                                </div>
                              </div>
                            </i>
                          </div>
                        </li>

                        <li class="d-flex align-items-center"
                            :class="{'line_strike': !item.limits.ab_testing,'plan-list':item.limits.ab_testing}">
                          <div class="d-inline-block icon"><i
                            :class="{'fa-check': item.limits.ab_testing, 'fa-times': !item.limits.ab_testing}"
                            class="fal  pricing-table-icon"></i></div>
                          <span>A/B Testing</span>
                          <div class="avatar mr-0 ml-3">
                            <i class="far fa-question-circle custom_tooltip tag_tooltip" title="Platform">
                              <div class="tool_tip_box">
                                <div class="inner_box">
                                  <p>A/B testing, refers to a randomized experimentation process wherein two or more
                                    versions of a variable web page are shown to different segments of website visitors
                                    based on provided weightage at the same time to determine which version leaves the
                                    maximum impact and drive business metrics.</p>
                                </div>
                              </div>
                            </i>
                          </div>
                        </li>

                        <li class="d-flex align-items-center"
                            :class="{'line_strike': !item.limits.whitelabel,'plan-list':item.limits.whitelabel}">
                          <div class="d-inline-block icon"><i
                            :class="{'fa-check': item.limits.whitelabel, 'fa-times': !item.limits.whitelabel}"
                            class="fal  pricing-table-icon"></i></div>
                          <span>Whitelabel</span>
                          <div class="avatar mr-0 ml-3">
                            <i class="far fa-question-circle custom_tooltip tag_tooltip" title="Platform">
                              <div class="tool_tip_box">
                                <div class="inner_box">
                                  <p>Whitelabel allows users to rebrand Replug as their own URL shortener service. When
                                    clients and potential viewers see your name on software, your business becomes
                                    trustworthy and reliable. Credibility helps you expand very fast. Offer features
                                    such as branded links, bio links, traffic routing, link analytics, and more with
                                    your own branding and expand your reach without the expense of creating a new
                                    product or features from scratch.</p>
                                </div>
                              </div>
                            </i>
                          </div>
                        </li>

                        <li class="d-flex align-items-center"
                            :class="{'line_strike': !item.limits.deep_linking,'plan-list':item.limits.deep_linking}">
                          <div class="d-inline-block icon"><i
                            :class="{'fa-check': item.limits.deep_linking, 'fa-times': !item.limits.deep_linking}"
                            class="fal  pricing-table-icon"></i></div>
                          <span>Deep Linking</span>
                          <div class="avatar mr-0 ml-3">
                            <i class="far fa-question-circle custom_tooltip tag_tooltip" title="Platform">
                              <div class="tool_tip_box">
                                <div class="inner_box">
                                  <p>Allows your users to open the shortened links inside relevant application installed
                                    on mobile devices. Supported on both iOS and Android.</p>
                                </div>
                              </div>
                            </i>
                          </div>
                        </li>

                        <li :class="{'line_strike': !item.limits.dedicated_support,'plan-list':item.limits.dedicated_support}">
                          <div class="d-inline-block icon"><i
                            :class="{'fa-check': item.limits.dedicated_support, 'fa-times': !item.limits.dedicated_support}"
                            class="fal  pricing-table-icon"></i></div>
                          <span>Dedicated Support</span>
                        </li>
                        <li :class="{'line_strike': !item.limits.api_access,'plan-list':item.limits.api_access}">
                          <div class="d-inline-block icon"><i
                            :class="{'fa-check': item.limits.api_access, 'fa-times': !item.limits.api_access}"
                            class="fal  pricing-table-icon"></i></div>
                          <span>API Access</span>
                        </li>

                        <li :class="{'line_strike': !item.limits.onboarding_and_call_support,'plan-list':item.limits.onboarding_and_call_support}">
                          <div class="d-inline-block icon"><i
                            :class="{'fa-check': item.limits.onboarding_and_call_support, 'fa-times': !item.limits.onboarding_and_call_support}"
                            class="fal  pricing-table-icon"></i></div>
                          <span>Onboarding & Call Support</span>
                        </li>

                      </ul>
                      <div>
                        <button class="mt-3 upgrade_btn btn---cta btn-round pricing_btn "
                                v-if="item.level > level"
                                @click="upgradePlan(item)">
                          <span>Upgrade</span>
                          <clip-loader v-if="item.loader" style="margin-left:120px;margin-top:-23px" :color="'#168eea'"
                                       :size="'14px'"></clip-loader>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="credit_card_container container mt-5">
          <div class="d-flex flex-row justify-content-around flex-wrap">
            <div class="credit_card_item-1">
              <img src="@/assets/img/credit-card/card_visa.png" fluid alt="Fluid image"/>
            </div>
            <div class="credit_card_item-2">
              <img src="@/assets/img/credit-card/card_amex.png" fluid
                   alt="Fluid image"/>
            </div>
            <div class="credit_card_item-3">
              <img src="@/assets/img/credit-card/card_mastercard.png" fluid
                   alt="Fluid image"/>
            </div>
            <div class="credit_card_item-4">
              <img src="@/assets/img/credit-card/card_paypal.png" fluid
                   alt="Fluid image"/>
            </div>
          </div>
        </div>
        <div class="container section partners-section">
          <h5 class="text-center"><strong>30-days</strong> money back guarantee</h5>
          <h2 class="text-center"><strong>10,000+ Marketers Already Trust {{ self.getSiteDetails.agency_name }}</strong>
          </h2>

          <div class="row text-center mt-5">
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
              <img class="img-fluid" src="@/assets/img/new-marketers/Group 17050.png" alt="Fluid image"/>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
              <img class="img-fluid" src="@/assets/img/new-marketers/Group 17051.png" alt="Fluid image"/>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
              <img class="img-fluid" src="@/assets/img/new-marketers/Group 17052.png" alt="Fluid image"/>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
              <img class="img-fluid" src="@/assets/img/new-marketers/Group 17053.png" alt="Fluid image"/>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
              <img class="img-fluid" src="@/assets/img/new-marketers/Unknown-6.png" alt="Fluid image"/>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
              <img class="img-fluid" src="@/assets/img/new-marketers/ogilvy-vector-logo.png" alt="Fluid image"/>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
              <img class="img-fluid"
                   src="@/assets/img/new-marketers/logo-mondelez-17206c289ec22aa33c625c7a69f57597.png"/>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
              <img class="img-fluid"
                   src="@/assets/img/new-marketers/logo-wpp-d103e0310bd037ecaeae1d81725cbb05.png"/>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
              <img class="img-fluid" src="@/assets/img/new-marketers/Unknown.png" alt="Fluid image"/>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
              <img class="img-fluid" src="@/assets/img/new-marketers/Unknown-2.png" alt="Fluid image"/>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
              <img class="img-fluid" src="@/assets/img/new-marketers/Unknown-4.png" alt="Fluid image"/>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
              <img class="img-fluid" src="@/assets/img/new-marketers/Unknown-5.png" alt="Fluid image"/>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 ml-auto">
              <img class="img-fluid" src="@/assets/img/new-marketers/Unknown-1.png" alt="Fluid image"/>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 mr-auto">
              <img width="95" class="img-fluid bmw_logo" src="@/assets/img/new-marketers/Unknown-3.png"
                   alt="Fluid image"/>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { pricingList } from '@/mixins/pricing_constants'
import http from '@/mixins/http-lib'
import { getOverridePriceURL } from '@/config/api'
import { NEW_LTD_PRIME_PLANS, OLD_LTD_SUBSCRIPTIONS } from '@/common/constants'

export default (
  {
    name: 'UpgradeSubscriptionModal',
    data: function () {
      return {
        plan_type: 'monthly',
        pricing_list: pricingList,
        policy: {
          can_view_billing: false
        },
        level: 0,
        isUpdatePlan: false,
        subscriptionList: [],
        displayNameList: ['Basic', 'Professional', 'Agency', 'Agency unlimited 10 brands', 'Agency unlimited 20 brands', 'Agency unlimited 50 brands']
      }
    },
    props: [
      'self'
    ],
    async mounted () {
      if (this.self.getProfile.is_trial_expired) {
        await this.self.$bvModal.show('upgrade-subscription-modal')
      }

      this.policy.can_view_billing = this.self.getSubscription.fastspring_name && this.self.getProfile.policy.can_view_billing
    },
    async created () {
      await this.fetchSubscriptions()

    },
    computed: {
      ...mapGetters(['getProfile', 'getSubscription'])
    },
    methods: {
      ...mapActions(['upgradeSubscription', 'fetchSpecifiedSubscriptionsList']),
      async setLevel () {
        let internalId = await this.self.getProfile.subscription.internal_id
        this.subscriptionList.forEach(item => {
          if (internalId === item.internal_id) {
            this.level = item.level
          }
        })
      },
      /**
       * Fetch a list of specified subscriptions using the provided names.
       * @returns {Promise<void>}
       */
      async fetchSubscriptions () {
        this.subscriptionList = await this.fetchSpecifiedSubscriptionsList(NEW_LTD_PRIME_PLANS)

        this.subscriptionList = await this.subscriptionList.map((item, index) => {
          return {
            ...item,
            display_name: this.displayNameList[index],
            loader: false,
            level: index + 1
          }
        })

        await this.setLevel()
      },
      /**
       * Computes the display value for limits, such as usage limits or thresholds.
       * If the provided value is greater than 1000001, returns 'Unlimited';
       * otherwise, returns the original value.
       *
       * @param {number} value - The numerical value to be processed.
       * @returns {string|number} - The computed display value.
       */
      computeLimitsValue(value) {
        return value > 1000001 ? 'Unlimited' : value
      },
      /**
       * checking if user has old subscription
       * @returns {boolean}
       */
      isOldLTDSubscription () {
        return OLD_LTD_SUBSCRIPTIONS.includes(this.self.getProfile.subscription.fastspring_name)
      },
      /**
       * Check if the user's subscription is in the list of old LTD subscriptions
       * @param price
       * @returns {number|*}
       */
      computeOverridePrice (price) {
        return this.isOldLTDSubscription() ? price : (price - this.self.getProfile.subscription.price)
      },
      async upgradePlan (item) {
        item.loader = true

        setTimeout(() => {
        http.post(getOverridePriceURL, {
          paddle_id: item.paddle_id,
          price: this.computeOverridePrice(item.price),
        }).then(
          resp => {

            var paddleOptions = {
              product: item.paddle_id,
              email: this.getProfile.email,
              title: item.display_name,

              price: this.computeOverridePrice(item.price),
              passthrough: this.isOldLTDSubscription() ? JSON.stringify({old_subscription: this.self.getProfile.subscription}) : '',
              allowQuantity: false,
              disableLogout: true,
              successCallback: function (data) {
                console.log(data)
                window.fpr('referral', {email: data.email, uid: data.id})
                window.location.reload()
              }
            }

            paddleOptions.auth = resp.data.token

            Paddle.Checkout.open(paddleOptions)
          }
        ).catch()
      }, 500)
      }
    }

  })
</script>

<style scoped lang="less">

.subscriptions_plan {
  padding-left: 0 !important;

  .main {
    position: relative;
    padding: 40px;

    .pricing_page {
      .heading_x {
        h2 {
          margin-top: 10px;
          font-size: 2.3rem !important;
          line-height: 1.3;
          color: #1f216e !important;
          font-weight: 800;
          text-align: center;
        }

        .mt-3 {
          color: #1f216e !important;
          margin-top: 10px;
          font-size: 1rem;
          font-weight: lighter;
          opacity: 0.7;
          text-align: center;
        }
      }

      .pricing__box {
        .toggle_btn_single {
          text-align: center;

          .discount_wrapper {
            display: inline-block;
            position: relative;

            .toggle_btn_inner {
              position: relative;
              display: inline-block;
              //border: solid 1px rgba(143, 144, 182, 0.5);
              overflow: hidden;
              background: #eff6fc;
              //.border-radius(30px);
              padding: 4px;
            }

            .btn_month {
              margin-left: -25px;
              letter-spacing: 1px;

              span {
                font-size: 1rem !important;
              }
            }

            .btn_year {
              float: right;
              margin-right: 1px;
              letter-spacing: 1px;

              span {
                font-size: 1rem !important;
              }
            }

            .active_move {
              background: #f4f4f7;
              padding: 8px 20px;
              min-width: 150px;
              height: ~"calc(100% - 6px)";
              top: 3px;
              position: absolute;
              //.border-radius(30px);
              //.transition_linear;
              background: white;
            }

            .active_move.active_left {
              left: 4px;
            }

            .active_move.active_right {
              left: 145px;
            }

            button {
              position: relative;
              min-width: 96px;
              background: transparent;
              border: none;
              box-shadow: none;
              text-transform: none;

              span {
                font-size: 14px;
                font-weight: bold;
                color: #1f216e;
                //.transition_linear;
              }
            }

            .active {
              // background: @green2;
              span {
                color: #45a2ff;
              }
            }

            .row {
              .discount-text {
                font-size: 0.8rem;
                color: #22c07f;
                text-align: center;
                margin-left: 70px;
              }

              img {
                width: 60px;
                margin-top: -45px;
                height: 80px;
                margin-left: 13px
              }
            }
          }
        }

        .inner {
          padding: 25px 15px;

          .list_detail {
            letter-spacing: 1px;
            margin-top: -35px;
            padding: 15px;
            width: 100%;

            li {
              font-size: 1rem !important;
            }

            .brand-li {
              div {
                background: white;
                padding: 10px;
                width: 410px;
                margin-left: -38px;
                opacity: 0.2;
              }
            }
          }

          .package {
            font-size: 18px;
            font-weight: 800;
            //color: @font_color;
            text-align: center;
            letter-spacing: 2.4px;
            font-size: 1.3rem !important;
          }

          .price {
            font-size: 3rem;
            font-weight: 1000;
            margin: 40px 0;
            //color: @font_color;
            text-align: center;

            sup, sub {
              font-size: 18px;
              font-weight: 600;
              font-style: normal;
            }
          }

          .pricing_btn {
            font-size: 16px;
            font-weight: bold;

          }

          .current_plan_btn {
            border: 1px solid #2dd678;
            cursor: not-allowed;
            background: white;

            span {
              color: #2dd678;
            }

            &:hover {
              background: #2dd678;

              span {
                color: white;
              }
            }
          }
        }
      }
    }

    .btn_block {
      margin-top: 30px;
    }
  }
}

.blur-plan {
  opacity: 0.5;
}

.brand {
  background: #4871f8;
  margin: 0 -15px;
  padding: 10px 0;
  font-weight: bold;
  font-size: 16px;
  margin-top: -10px;
  margin-bottom: 30px;
  text-align: center;
}

.plan-list {
  margin-top: 0px !important;
  font-weight: lighter !important;
  font-size: 0.1rem !important;
}

.line_strike {
  margin-top: 0px !important;
  font-weight: lighter !important;
  font-size: 0.1rem !important;

  span {
    opacity: 0.7 !important;
  }
}

.white_bg {
  background-image: url('/assets/img/plans/white.png') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;

  .inner_section {
    .brand {
      background: #f5f5f5;

      .bold {
        background: #f5f5f5;
      }
    }

    .discounted_price {
      color: #1f216e !important;
      font-weight: 600;
      font-size: 30px;
      text-decoration: line-through
    }

    .list_detail {
      .plan-list {
        color: #1f216e !important;

        span {
          color: #1f216e !important;
          margin-left: 20px;
        }

        .icon {
          background: #23daa9;
          width: 27px;
          border-radius: 50px;
          height: 26px;

          .fa-check {
            font-weight: normal;
            font-size: large;
            margin-left: 4px;
            color: white !important;
            margin-top: 4px;
          }
        }
      }

      .line_strike {
        span {
          color: rgba(31, 33, 110, 0.5) !important;
          margin-left: 20px;
        }

        .avatar {
          .fa-question-circle {
            color: #45a2ff
          }
        }


        .icon {
          background: #f72c5b;
          width: 27px;
          border-radius: 50px;
          height: 26px;

          .fa-times {
            font-weight: normal;
            font-size: large;
            margin-left: 8px;
            color: white !important;
            margin-top: 4px;
          }
        }
      }
    }

    .btn---cta {
      width: 100%;
      padding: 15px;
      background: whitesmoke;

      span {
        color: #0d44fb;
      }
    }
  }
}

.green_bg {
  background-image: url("/assets/img/plans/green.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;

  .inner_section {
    color: white !important;

    .package {
      color: white !important;
    }

    .price {
      color: white !important;

      .discounted_price {
        color: white !important;
        font-weight: 600;
        font-size: 30px;
        text-decoration: line-through;
        margin: 25px 0;
      }
    }

    .brand {
      color: white !important;

      span {
        color: white !important;
      }
    }

    .list_detail {
      li {
        color: white !important;

        span {
          color: white !important;
        }
      }
    }

    .brand {
      background: #39dd99;

      .bold {
        background: #39dd99;
      }
    }

    .list_detail {
      .plan-list {
        span {
          margin-left: 20px;
        }

        .icon {
          background: white;
          width: 27px;
          border-radius: 50px;
          height: 26px;

          .fa-check {
            font-weight: normal;
            font-size: large;
            margin-left: 4px;
            color: #23daa9 !important;
            margin-top: 4px;
          }
        }

        .avatar {
          .fa-question-circle {
            color: white;
          }
        }
      }

      .line_strike {
        span {
          margin-left: 20px;
        }

        .avatar {
          .fa-question-circle {
            color: white;
          }
        }

        .icon {
          background: white;
          color: #f72c5b;
          width: 27px;
          border-radius: 50px;
          height: 26px;

          .fa-times {
            font-weight: normal;
            font-size: large;
            margin-left: 7px;
            color: #f72c5b !important;
            margin-top: 4px;
          }
        }
      }
    }

    .btn---cta {
      width: 100%;
      padding: 15px;
      background: whitesmoke;

      span {
        color: #0d44fb;
      }
    }
  }
}

.blue_bg {
  background-image: url("/assets/img/plans/blue.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;

  .inner_section {
    color: white !important;

    .package {
      color: white !important;
    }

    .price {
      color: white !important;

      .discounted_price {
        color: white !important;
        font-weight: 600;
        font-size: 30px;
        text-decoration: line-through;
        margin: 25px 0;
      }
    }

    .brand {
      color: white !important;

      span {
        color: white !important;
      }
    }

    .list_detail {
      li {
        color: white !important;

        span {
          color: white !important;
        }
      }
    }

    span {
      margin-left: 20px;
    }

    .brand {
      background: #4871f8;

      .bold {
        background: #4871f8;
      }
    }

    .list_detail {
      .plan-list {
        .icon {
          background: white;
          width: 26px;
          border-radius: 50px;
          height: 26px;

          .fa-check {
            font-weight: normal;
            font-size: large;
            margin-left: 4px;
            color: #23daa9 !important;
            margin-top: 4px;
          }
        }

        .avatar {
          .fa-question-circle {
            color: white;
          }
        }
      }

      .line_strike {
        .avatar {
          .fa-question-circle {
            color: white;
          }
        }

        .icon {
          background: white;
          color: #f72c5b;
          width: 26px;
          border-radius: 50px;
          height: 26px;

          .fa-times {
            font-weight: normal;
            font-size: large;
            margin-left: 5px;
            color: #f72c5b !important;
            margin-top: 4px;
          }
        }
      }
    }

    .btn---cta {
      width: 100%;
      padding: 15px;
      background: whitesmoke;

      span {
        color: #0d44fb;
      }
    }
  }
}

.month {
  font-size: 0.9rem !important;
  opacity: 0.7;
}

.year {
  font-size: 0.9rem !important;
  opacity: 0.7;
}

.brands_section {
  .green_bg {
    background-image: url("/assets/img/plans/green.png") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;

    .inner_section {
      color: white !important;

      .package {
        color: white !important;
      }

      .price {
        color: white !important;

        .discounted_price {
          color: white !important;
          text-decoration: line-through;
          font-weight: 600;
          font-size: 30px;
          margin: 25px 0;
        }
      }

      .brand {
        color: white !important;

        span {
          color: white !important;
        }
      }

      .list_detail {
        li {
          color: white !important;

          span {
            color: white !important;
          }
        }
      }

      .brand {
        background: #39dd99;

        .bold {
          background: #39dd99;
        }
      }

      .list_detail {
        .plan-list {
          span {
            margin-left: 20px;
          }

          .icon {
            background: white;
            width: 27px;
            border-radius: 50px;
            height: 26px;

            .fa-check {
              font-weight: normal;
              font-size: large;
              margin-left: 4px;
              color: #23daa9 !important;
              margin-top: 4px;
            }
          }
        }

        .line_strike {
          span {
            margin-left: 20px;
          }

          .icon {
            background: white;
            color: #f72c5b;
            width: 27px;
            border-radius: 50px;
            height: 26px;

            .fa-times {
              font-weight: normal;
              font-size: large;
              margin-left: 7px;
              color: #f72c5b !important;
              margin-top: 4px;
            }
          }
        }
      }

      .btn---cta {
        width: 100%;
        padding: 15px;
        background: whitesmoke;

        span {
          color: #0d44fb;
        }
      }
    }
  }

  .blue_bg {
    background-image: url("/assets/img/plans/blue.png") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;

    .inner_section {
      color: white !important;

      .package {
        color: white !important;
      }

      .price {
        color: white !important;

        .discounted_price {
          color: white !important;
          text-decoration: line-through;
          font-weight: 600;
          font-size: 30px;
          margin: 25px 0;
        }
      }

      .brand {
        color: white !important;

        span {
          color: white !important;
        }
      }

      .list_detail {
        li {
          color: white !important;

          span {
            color: white !important;
          }
        }
      }

      span {
        margin-left: 20px;
      }

      .brand {
        background: #4871f8;

        .bold {
          background: #4871f8;
        }
      }

      .list_detail {
        .plan-list {
          .icon {
            background: white;
            width: 26px;
            border-radius: 50px;
            height: 26px;

            .fa-check {
              font-weight: normal;
              font-size: large;
              margin-left: 4px;
              color: #23daa9 !important;
              margin-top: 4px;
            }
          }
        }

        .line_strike {
          .icon {
            background: white;
            color: #f72c5b;
            width: 26px;
            border-radius: 50px;
            height: 26px;

            .fa-times {
              font-weight: normal;
              font-size: large;
              margin-left: 5px;
              color: #f72c5b !important;
              margin-top: 4px;
            }
          }
        }
      }

      .btn---cta {
        width: 100%;
        padding: 15px;
        background: whitesmoke;

        span {
          color: #0d44fb;
        }
      }
    }
  }

  //padding: 20px;
  //height: 106px;
  img {
    //padding: 0 60px;
    //margin-bottom: 3rem !important;
    height: 109px;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.9rem 2.3rem;
  }

  .bmw_logo {
    height: 121px;
  }
}

.credit_card_container {
  width: 30em;

  .credit_card_item-1 {
    img {
      width: 70px
    }
  }

  .credit_card_item-2 {
    img {
      width: 100px;
      margin-top: -1px;
    }
  }

  .credit_card_item-3 {
    img {
      width: 45px;
      margin-top: -2px
    }
  }

  .credit_card_item-4 {
    img {
      width: 90px;
      margin-top: -10px;
    }
  }
}

.partners-section {
  .row > div {
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 106px;

    img {
      padding: 30px 20px;
    }
  }
}

.section {
  h5 {
    color: #1f216e;
    margin-top: 20px;
  }

  h2 {
    color: #1f216e;
    font-size: 2.1rem;
    margin-top: 100px
  }
}

.close_modal {
  float: right;
  margin-right: 20px;
  margin-top: 20px;
  font-size: x-large;
}
</style>
